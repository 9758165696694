@import "../variables";

.manager-page {
  background-color: $background-color;
  padding-left: 15px;
  padding-right: 15px; }

nav.collection-list {
  margin-bottom: 20px;
  max-height: 300px;
  overflow: auto;

  & > div {
    font-size: 14px;
    padding-bottom: 15px; }

  a {
    font-size: 12px;
    text-decoration: underline; } }
