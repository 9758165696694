@import "../variables";

.account-setup-page {
  background-color: $background-color;

  .card-medium {
    width: 70%;
    background-color: #fff;

    span {
      font-weight: 600; } }

  .account-card-title {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: 300; }

  button.account-button {
    margin-bottom: 20px; }

  .invalid-password {
    padding-bottom: 0px;

    p {
      font-size: 14px;
      font-weight: 300;
      color: $gray-light; } }

  .org-account-info {
    padding-bottom: 30px;

    label {
      width: 70%; } } }
