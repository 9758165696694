@import "../variables";

.import-page {
  width: 50%;
 }  //margin-top: 40px

.import-h2 {
  padding-top: 30px;
  text-align: center; }

.import-button {
  margin-top: 25px;
  margin-bottom: 20px; }

.import-fields-box {
  margin-top: 20px; }
