// NOTE: Frow CSS's variables are automatically included as overwritable defaults.
// See them all at: http://frowcss.com/customize.html

$font: "Montserrat", sans-serif;

$dark-background-color: #353535;
$dark-white: #4A4A4A;

$blue: #3b96d8;
$green: #00a96c;
$red: #f16362;
$orange: #faa85d;
$yellow: #fec362;

$underweight: #90c680;
$healthy-weight: #48b16c;
$overweight: #fcc65c;
$obese: #f7aa57;
$morbidly-obese: #eb5a5d;

$primary-color: $blue;

$hover: #1a1a1a;

$header-height: 56px;
$main-view-padding: 14px;
$ios-header-height: 20px;
$android-header-height: 24px;

// Keep at bottom of file
@import "~frow/variables";
