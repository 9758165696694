@import "../variables";

.is-native .remember-me {
  display: none; }

.links {
  padding-top: 15px; }

.forgot-password-link {
  margin-top: 30px;
  font-size: 14px; }

.log-in-logo {
  height: 120px;
  width: 200px;
  margin: 0 30px; }

.log-in-card {
  max-width: 400px;
  background-color: $blue;
  color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 2px 0 10px rgba(0, 0, 0, 0.28);
  overflow: auto;
  font-size: 18px;
  padding: 20px 20px 40px;

  label {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    padding: 4px 0; }

  input, textarea {
    color: #fff;
    border-color: #fff;
    font-size: 20px;

    &::-webkit-input-placeholder {
      color: #ddd;
      text-transform: lowercase; }

    &::-moz-placeholder {
      color: #ddd;
      text-transform: lowercase; }

    &:-ms-input-placeholder {
      color: #ddd;
      text-transform: lowercase; }

    &:hover {
      border-color: #fff; }

    &:active, &:focus {
      border-color: $off-black; } }

  .log-in-button {
    background: none;
    border: 2px solid #fff;
    border-radius: 3px;
    margin: 0 5px;
    width: 100px;

    &:disabled {
      background: repeating-linear-gradient(-45deg, $blue + $hover * 1.5, $blue + $hover * 1.5 3px, $blue 3px, $blue 6px);
      border-color: $blue + $hover * 1.5;
      color: rgba(255,255,255,0.9);

      &:hover, &:focus, &:active {
        border-color: $blue + $hover * 1.5;
        opacity: 0.7; } } } }
