@import "../../variables";

.sidebar {
  width: 260px;
  flex-shrink: 0; }

.sidebar-card {
  width: 100%;
  height: auto;
  opacity: 1;
  background-color: $gray-darker;
  overflow: hidden;
  box-shadow: inset -17px 30px 40px -20px #000;

  .sidebar-items {
    padding-top: 60px;
    text-align: center;
    min-width: 200px;
    width: 100%;
    overflow-y: auto;

    a, a:hover, a:active, a:visited, .clickable {
      font-weight: 400;
      font-size: 22px;
      border-bottom: 1px dashed $gray-dark;
      padding: 20px 0;
      text-decoration: none;
      color: #fff;
      display: block; } }

  .sidebar-logo {
    width: 60px;
    margin: 20px 0; } }

.fixed-sidebar {
  position: fixed;
  z-index: 8;

  &.v-enter-active, &.v-leave-active {
    transition: transform $animate-speed;
    transform: translateX(0); }

  &.v-enter, &.v-leave-to {
    transform: translateX(-100%); }

  .sidebar-card {
    position: static;
    width: 20%;
    min-width: 264px;
    box-shadow: 17px 30px 40px -20px #000; } }
