@import "../variables";

@page {
  size: letter; }

@media print {
  .page-break {
    page-break-after: always; } }

@media screen {
  .dynamic-3-4 {
    width: 75%; } }

@media print {
  .dynamic-3-4 {
    width: 100%; } }

@keyframes pulse {
  from {
    opacity: 0.3; }
  to {
    opacity: 1.0; } }

.print-form {
  padding: 30px;
  color: #000;
  overflow: auto;
  font-size: 14px;

  .print-section {
    border-top: 1px solid #000;
    padding: 15px 0 10px;

    &:first-of-type {
      border-top: none; } }

  .print-sentence {
    font-size: 12px; } }

@media print {
  .print-form {
    padding: 0;
    overflow: visible; } }

.logo {
  height: 100%;
  max-height: 40px; }

.logo-wds {
  padding-top: 50px;

  p {
    font-size: 16px; }

  svg {
    max-height: 40px; } }

.print-name {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px; }

.print-date {
  font-size: 20px;
  margin-top: 10px;
  font-weight: 700; }

.print-basic-info {
  margin: 10px 0;
  padding: 5px;
  border: 2px solid $off-black;

  .print-basic-info-column {
    max-width: 250px; } }

.print-section-title {
  font-size: 20px;
  font-weight: 700; }

.print-formula-line {
  margin: -18px 0 20px;

  .print-formula {
    margin-left: 10px; } }

.print-bmi-table {
  width: 100%;
  text-align: center;

  th, td {
    padding: 5px 5px; }

  th {
    background-color: $gray-light;
    color: #fff;
    font-weight: 700; }

  tr {
    td:first-of-type {
      text-align: left; } }

  .underweight {
    background-color: $underweight; }

  .healthy-weight {
    background-color: $healthy-weight; }

  .overweight {
    background-color: $overweight; }

  .obese {
    background-color: $obese; }

  .morbidly-obese {
    background-color: $morbidly-obese; } }

.font-color-light-green {
  color: $underweight; }

.font-color-green {
  color: $healthy-weight; }

.font-color-gold {
  color: $overweight; }

.font-color-orange {
  color: $obese; }

.font-color-red {
  color: $morbidly-obese; }

.cell {
  text-align: center;
  height: 25px;
  border: none;
  color: $off-black;
  font-size: 18px;
  margin: 1px;

  .cell-score {
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 50%;
    font-size: 12px;
    padding: 3px; } }


.green-cell {
  background-color: $healthy-weight; }

.yellow-cell {
  background-color: $overweight; }

.red-cell {
  background-color: $morbidly-obese; }

.print-table-gradient {
  background: linear-gradient(to bottom, $underweight 0%, $underweight 10%, $healthy-weight 30%, $overweight 50%, $obese 70%, $morbidly-obese 90%, $morbidly-obese 100%);
  height: 147px;
  width: 30px;
  margin-bottom: 2px;
  position: relative;

  .print-table-arrow {
    position: absolute;
    width: 30px;
    top: 0%;
    left: 10px;
    margin-top: -13px;
    transition: top 1s;

    svg {
      transform: rotate(90deg); } } }

.print-right-results {
  border: 2px solid $off-black;
  padding: 10px;

  & > .frow {
    margin-bottom: 10px; } }

.print-box-label, .print-box {
  font-size: 14px; }

.print-box-label {
  margin-right: 5px; }

.print-box {
  width: 60px;
  border: 2px solid #000;
  text-align: center;
  padding: 3px;
  min-height: 28px; }

.print-right-final-score {
  margin-top: 20px; }

.print-final-title {
  font-size: 18px;
  margin-right: 5px;
  font-weight: 700; }

.print-final-score {
  font-size: 25px;
  width: 45px;
  border: 2px solid #000;
  text-align: center;
  padding: 5px;
  height: 45px;
  border-radius: 40px;
  line-height: 120%; }

.print-mets-factor {

  .no-bullet {
    list-style-type: none; }

  .print-mets-factor-title {
    font-size: 20px;
    margin: 30px 0 10px; }

  ul {
    font-size: 14px;

    li {
      list-style-type: disc;
      margin: 0 0 5px 20px;

      ul {
        li {
          list-style-type: circle; } } } } }

.print-intervention {
  width: 40px;
  margin-top: 40px;

  svg {
    fill: red;
    stroke: red; } }
.print-mets-factor-title.print-intervention {
  margin-top: 0;

  li {
    color: $red; } }

.danger {
  border: 2px solid $red; }
