@import "../variables";

.admin-page {
  background-color: $background-color; }

.border-line {
  border-right: 1px solid $gray-lighter; }

.admin-category {
  margin-bottom: 30px; }

.admin-category-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px; }

.manage-views {
  a {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    background-color: $green;
    border-color: $green;
    margin-bottom: 5px;

    &:hover, &:active, &:focus {
      background-color: $green * 0.8;
      border-color: $green * 0.8; } } }

.manage-options {
  label {
    color: $off-black; } }

nav.admin-list {
  min-height: 200px;

  & > div {
    padding: 20px 10px;
    cursor: pointer;

    .child-nav {
      font-size: 14px;
      padding-left: 10px;

      &:hover {
        background-color: lighten($blue, 10%); } }

    &.active-nav {
      background-color: $blue;
      color: #fff;

      &:hover, &:active {
        background-color: $blue; }

      a {
        color: #fff; }

      .active-child-nav {
        background-color: darken($blue, 10%);
        color: #fff;

        &:hover, &:active {
          background-color: darken($blue, 10%); }

        a {
          color: #fff; } } }

    &:hover, &:active {
      background-color: $gray-lighter; }

    .nav-primary {
      font-weight: 700; }

    .nav-secondary {
      font-size: 12px; }

    a.nav-secondary {
      text-decoration: underline; } } }

.disclaimer {
  margin-top: 10px;
  font-size: 12px;
  text-align: center; }
