@import "../../variables";

#editor {
  margin: 0;
  height: 100%;

  .left-column {
    padding: 0 10px;
    border-right: 1px solid #ccc; }

  .delete {
    background: transparent;
    border-color: $red;
    color: $red;

    &.confirm {
      background: $red;
      color: $button-text-color; } }

  textarea, .editor-output {
    padding: 0 20px;
    flex: 1; }

  textarea {
    border: none;
    resize: none;
    background-color: #f6f6f6;
    font-size: 14px;
    // font-family: 'Monaco', courier, monospace
    padding: 20px; }

  .editor-output {
    overflow: auto;

    & /deep/ h1 {
      color: $red;

      &::after {
       content: 'WARNING: Do not use any h1\'s in the Markdown';
       font-size: 14px;
       display: block;
       background-color: #000; } }

    & /deep/ img {
      max-width: 100%; } } }
