@import "../variables";

@page {
  size: letter; }

@media print {
  .do-not-print, .do-not-print.frow {
    display: none !important; } }

@media print {
  .print-contain {
    padding: 0;
    overflow: visible; } }

@media screen {
  .dynamic-3-4 {
    width: 75%; } }

@media print {
  .dynamic-3-4 {
    width: 100%; } }

.risk-sidebar {
  max-width: 400px;
  background-color: $gray-light;
  color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 2px 0 10px rgba(0, 0, 0, 0.28);
  overflow: auto;
  font-size: 18px;

  form {
    margin: 15px;

    .sidebar-group {
      border-top: 1px dashed #fff;
      padding: 25px 20px 15px;

      &:first-child {
        border-top: none; } } }

  label {
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    margin-top: 20px;
    padding: 4px 0; }

  .print-checkbox-row {
    label {
      margin-top: 0;
      padding: 1px 0 0 8px;
      font-weight: 400; }

    &:first-child {
      margin-top: 10px; } }

  input, textarea {
    color: #fff;
    border-color: #fff;
    font-size: 20px;

    &::-webkit-input-placeholder {
      color: #ddd;
      text-transform: lowercase; }

    &::-moz-placeholder {
      color: #ddd;
      text-transform: lowercase; }

    &:-ms-input-placeholder {
      color: #ddd;
      text-transform: lowercase; }

    &:hover {
      border-color: #fff; }

    &:active, &:focus {
      border-color: $off-black; }

    &[type="radio"] {
      border-color: #fff;

      &:checked {
        background-color: #fff;
        box-shadow: inset 0 0 0 2px $gray-light;

        &:hover, &:focus {
          border-color: #000;
          background-color: #fff; }

        &:disabled {
          box-shadow: inset 0 0 0 2px #fff;
          border-color: darken($gray-lighter, 5%);
          background: none;
          background-color: darken($gray-lighter, 5%); } } } }

  select {
    border-color: #fff;
    color: #fff;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' version='1.1' xml:space='preserve' stroke-linejoin='round'><g transform='matrix(-0.613836,-7.51732e-17,7.51732e-17,-0.613836,19.9111,16.8669)'><path d='M12.9 2.9L22.7 12.9 3.1 12.9 12.9 2.9Z' fill='rgb(255,255,255)'/></g></svg>");

    &:hover {
      border-color: #000;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' version='1.1' xml:space='preserve' stroke-linejoin='round'><g transform='matrix(-0.613836,-7.51732e-17,7.51732e-17,-0.613836,19.9111,16.8669)'><path d='M12.9 2.9L22.7 12.9 3.1 12.9 12.9 2.9Z' fill='rgb(0,0,0)'/></g></svg>"); }


    &:active, &:focus {
      border-color: #000;
      color: #000;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' version='1.1' xml:space='preserve' stroke-linejoin='round'><g transform='matrix(-0.613836,-7.51732e-17,7.51732e-17,-0.613836,19.9111,16.8669)'><path d='M12.9 2.9L22.7 12.9 3.1 12.9 12.9 2.9Z' fill='rgb(0,0,0)'/></g></svg>"); } } }


label.autocomplete {
  position: relative;

  nav {
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 100%;
    color: $off-black;
    max-height: 250px;
    font-size: 14px;
    overflow: auto;

    & > div {
      padding: 5px;

      &:hover {
        background-color: $gray-lighter; }

      &:first-child {
        padding: 10px 5px; }

      div:last-child:not(:first-child) {
        font-weight: 400;
        font-size: 12px; } } } }


.sidebar-button {
  background: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  margin: 10px 5px;
  width: 45%;
  color: $gray-dark;

  &:hover, &:active, &:focus {
    background: #fff;
    border-color: #000;
    color: #000; }

  &.text-only {
    color: #fff;
    background: transparent;
    border-color: transparent;

    &:focus {
      border-color: #000; } } }

@keyframes pulse {
  from {
    opacity: 0.3; }
  to {
    opacity: 1.0; } }

.saving-message {
  animation: pulse 1s infinite alternate; }
