@import "../../variables";

@keyframes pop-in {
  0% {
    transform: scale(0);
    opacity: 0; }

  90% {
    transform: scale(1.05); }

  100% {
    transform: scale(1);
    opacity: 1; } }

.alerts-container {
  height: 100%;
  width: 100%;
  max-width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  overflow: auto;
  padding: 25px;
  pointer-events: none;
  border: 2px solid transparent;

  .dashboard-route & {
    z-index: 11; }

  span {
    text-transform: capitalize; }

  .error {
    color: #fff;
    background-color: $red;
    border: 2px solid $red;
    .alert-container-close {
      color: #fff; } }

  .success {
    color: #fff;
    background-color: $green;
    border: 2px solid $green;
    .alert-container-close {
      color: #fff; } }

  .info {
    color: #fff;
    background-color: $blue;
    border: 2px solid $blue;
    .alert-container-close {
      color: #fff; } } }


.alert-container {
  background-color: #fff;
  padding: 25px;
  animation: pop-in $animate-speed;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 2px 10px rgba(0, 0, 0, 0.28);
  position: relative;
  margin-bottom: 20px;
  pointer-events: auto;
  top: 50px;

  .alert-container-close {
    position: absolute;
    top: 10px;
    right: 10px;

    &::before, &::after {
      content: '';
      display: block;
      height: 4px;
      width: 20px;
      transform: rotate(45deg);
      position: absolute;
      top: 5px;
      right: 0px;
      background-color: inherit;
      background-color: #fff; }

    &::after {
      transform: rotate(-45deg); } } }

/* XS, SM */
@media (max-width: 991px) {
  .alerts-container {
    top: 50px; } }
