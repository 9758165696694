@import "../../variables";

.report {
  overflow-y: hidden;
  margin: 2px;
  transition: margin $animate-speed;

  &.expanded {
    margin-top: 15px;
    margin-bottom: 15px; } }

.cells {
  padding: 5px; }

.user-card {
  padding: 5px;
  background-color: $white;
  // margin-top: 5px
  position: relative;
  z-index: 2;
  box-sizing: content-box;

  * {
    box-sizing: border-box; } }

.birth-date {
  font-size: 12px; }

.wds-score {
  font-size: 20px;
  font-weight: 700; }

.risk-circle {
  border-radius: 50%;
  padding: 3px;
  height: 24px;
  width: 24px;
  display: inline-block;
  font-weight: 900;
  color: $white;

  &.risk-1 {
    background-color: $healthy-weight * 1.25; }

  &.risk-2 {
    background-color: $healthy-weight; }

  &.risk-3 {
    background-color: $overweight; }

  &.risk-4 {
    background-color: $obese; }

  &.risk-5 {
    background-color: $morbidly-obese; }

  &.risk-6 {
    background-color: $morbidly-obese * 0.75; } }

.additional-info {
  background-color: $white;
  margin: 0 15px;
  padding: 10px;
  position: relative;
  z-index: 1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  &.v-enter-active, &.v-leave-active {
    transition: transform $animate-speed; }

  &.v-enter, &.v-leave-to {
    transform: translateY(-100%); }

  .info-title, .info {
    font-size: 12px; }

  .info-title {
    font-weight: 700;
    margin-right: 4px; } }
