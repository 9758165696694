@import "../../variables";

.header {
  height: 56px;
  width: 100%;
  background-color: $green;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 2px 10px rgba(0, 0, 0, 0.28);
  position: fixed;
  z-index: 9;

  .back-button-add, .back-button-remove {
    will-change: transform;
    transition: transform $animate-speed ease;
    -webkit-backface-visibilty: hidden; }

  .toggle-sidebar-button {
    transition: transform $animate-speed ease;
    height: 55px;
    width: 49px;
    padding: 15px 12px;

    div {
      will-change: transform;
      width: 19px;
      height: 4px;
      background-color: #ffffff;
      transition: transform $animate-speed ease;
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000; }

    div:nth-child(1) {
      margin: 3px 0 0 3px; }

    div:nth-child(2) {
      margin: 3px 0 0 3px; }

    div:nth-child(3) {
      margin: 3px 0 0 3px; } }

  .back-button {
    transform: rotate(-180deg);

    div:nth-child(1) {
      margin: 4px 0 0 5px;
      transform: translateX(8px) translateY(1px) rotate(45deg);
      width: 15px; }

    div:nth-child(2) {
      margin: 1px 0 0 6px;
      width: 16px; }

    div:nth-child(3) {
      margin: 2px 0 0 5px;
      transform: translateX(8px) translateY(-2px) rotate(-45deg);
      width: 15px; } }

  .header-logo {

    svg {
      margin: 0 auto;
      height: 30px;
      fill: #fff; } } }
