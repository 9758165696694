@import "../variables";

.insurance-table-page {
  width: 80%;
  margin: 50px auto; }

.insurance-table {

  input.cell, .cell {
    text-align: center;
    height: 50px;
    border: none;
    // margin-top: 10px
    // margin-left: 10px
    color: $off-black;
    font-size: 18px;

    &:placeholder {
      color: $off-black; } }

  .cell {
    margin: 1px;

    &.title {
      font-size: 20px;
      font-weight: 700; } }

  .green-cell {
    background-color: $healthy-weight + $hover;
    cursor: pointer; }

  .healthy-cell {
    background-color: $healthy-weight;
    cursor: pointer; }

  .yellow-cell {
    background-color: $overweight;
    cursor: pointer; }

  .red-cell {
    background-color: $obese + $hover;
    cursor: pointer; }

  .unhealthy-cell {
    background-color: $obese;
    cursor: pointer; }

  button {
    background: #fff;
    color: $off-black;
    border: none;
    width: 100%;
    height: 50px;
    border-radius: 0;

    &:hover, &:focus, &:active {
      background-color: #fff - $hover; } } }

.insurance-table-options {
  margin-top: 30px; }

.insurance-table-stats {
  width: 60%;
  margin: 30px auto;
  font-size: 30px;

  .frow {
    margin-bottom: 30px;

    .stat-label {
      text-align: right; }

    span {
      margin-left: 10px; }

    input {
      // border: none
      width: 110px;
      font-size: 30px;
      padding: 0;
      display: inline; } } }
