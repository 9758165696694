@import "../../variables";

.distribution-chart {
  & /deep/ .ct-series-a {
    path {
      fill: $healthy-weight; } }
  & /deep/ .ct-series-b {
    path {
      fill: $overweight; } }
  & /deep/ .ct-series-c {
    path {
      fill: $morbidly-obese; } }
  & /deep/ .ct-series-d {
    path {
      fill: $morbidly-obese - $hover * 2; } } }

.history-chart {
  & /deep/ .ct-series-a {
    path, .ct-point {
      stroke: $healthy-weight; } }
  & /deep/ .ct-series-b {
    path, .ct-point {
      stroke: $overweight; } }
  & /deep/ .ct-series-c {
    path, .ct-point {
      stroke: $morbidly-obese; } }
  & /deep/ .ct-series-d {
    path, .ct-point {
      stroke: $morbidly-obese - $hover * 2; } } }
