@import "../variables";

.developer-key {
  background-color: $gray-lighter;
  border: 0;
  border-radius: 3px;
  font-size: 12px;
  min-height: 100px; }

.developer-page {
  background-color: $background-color;

  ul {
    list-style-type: disc; }

  *:not(pre) > code {
    border-radius: 3px;
    background-color: $gray-lighter;
    color: $gray-darker;
    padding: 1px 5px; }

  pre code {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
 }    // background: $base

  code[class*="language-"],
  pre[class*="language-"] {
    color: #ccc;
    background: none;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none; }

  pre[class*="language-"] {
    border-radius: 3px;
    padding: 1em;
    margin: .5em 0;
    overflow: auto; }

   :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background: #2d2d2d; }

   :not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal; }

  .token {
    &.comment, &.block-comment, &.prolog, &.doctype, &.cdata {
      color: #999; }
    &.punctuation {
      color: #ccc; }
    &.tag, &.attr-name, &.namespace, &.deleted {
      color: #e2777a; }
    &.function-name {
      color: #6196cc; }
    &.boolean, &.number, &.function {
      color: #f08d49; }
    &.property, &.class-name, &.constant, &.symbol {
      color: #f8c555; }
    &.selector, &.important, &.atrule, &.keyword, &.builtin {
      color: #cc99cd; }
    &.string, &.char, &.attr-value, &.regex, &.variable {
      color: #7ec699; }
    &.operator, &.entity, &.url {
      color: #67cdcc; }
    &.important, &.bold {
      font-weight: bold; }
    &.italic {
      font-style: italic; }
    &.entity {
      cursor: help; }
    &.inserted {
      color: green; } } }
