@import "../variables";
@import "~frow";
@import "~chartist/dist/scss/chartist";

// TODO: frow-temporary
.bold {
  font-weight: 700; }

@page {
  size: letter; }

@media print {

  .do-not-print, .do-not-print.frow {
    display: none !important; }

  .page-break {
    page-break-after: always; } }

// *
//   transition: width $animate-speed, height $animate-speed
//   animation: none 0s

// HACK: Fix for v-show issue (https://github.com/vuejs/vue/issues/3761)
*[style*="display: none"],
.frow.visible-xs[style*="display: none"],
.frow.visible-sm[style*="display: none"],
.frow.visible-md[style*="display: none"],
.frow.visible-lg[style*="display: none"], {
  display: none !important; }

html, body {
  height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #fff; }

body {
  font-family: $font;
  line-height: normal;
  color: $off-black; }

input, textarea, select, button {
  font-family: $font; }

.invisible {
  opacity: 0; }

.none-width {
  width: 0px !important; }

.main-frow {

  & > div {
    transition: width $animate-speed; }

  & > .sidebar {
    &.v-enter-active, &.v-leave-active {
      transition: width $animate-speed; }

    &.v-enter, &.v-leave-to {
      width: 0; } } }

.main-view {
  padding-top: $header-height; }

@media print {
  .main-view {
    padding-top: 0;
    height: auto; } }

.template {
  height: 100%;
  overflow-y: auto; }

.router-view-padding {
  padding-top: 30px; }

@mixin card-rules {
  max-height: 95%;
  overflow-y: auto;
  margin: 0;
  width: 100%; }

.card-small {
  @include card-rules;
  max-width: 326px; }

.card-medium {
  @include card-rules; }

.setting-card {
  background-color: #fff;
  padding: 15px;
  margin: 10px 0 30px;
  overflow-y: auto;
  max-height: 600px;

  &.full-height {
    max-height: none; } }

.card-title {
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px; }

button, html [type="button"], [type="submit"] {
  background-color: $blue;
  border-color: $blue;

  &:hover, &:active, &:focus {
      background-color: $blue - $hover;
      border-color: $blue - $hover; }

  &.red-button {
    background-color: $red;
    border-color: $red;

    &:hover, &:active, &:focus {
      background-color: $red - $hover;
      border-color: $red - $hover; } } }

input[type="checkbox"] {
  &:checked, &:active {
    background-color: $blue;
    border-color: $blue; }

  &:hover, &:focus {
    border-color: $off-black; } }

.tabs {
  button {
    width: 100%;
    text-align: center;
    background: none;
    color: $off-black;
    border: none;
    border-bottom: 2px solid $gray-lighter;
    border-radius: 0px;
    margin-bottom: 20px;
    font-size: 14px;

    &:disabled {
      background: none;
      border: none;
      color: $blue;
      border-bottom: 2px solid $blue; } } }

.settings-buttons {
  button, [type="button"] {
    margin-right: 10px;
    width: 100px;

    &:last-child {
      margin-right: 0; } } }

.small-logo {
  height: 100%;
  max-height: 40px; }
