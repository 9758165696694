@import "../variables";

.dashboard-page {
  background-color: $background-color;
  padding-left: 15px;
  padding-right: 15px; }

.dashboard-checkbox {
  margin: 4px 0 0;
  color: $off-black;
  font-size: 15px; }

.chart-box {
  margin: 0 0 30px;
  padding: 15px;
  background-color: #fff; }

.ct-chart-pie {
  .ct-label {
    font-weight: 900; } }

.chart-title {
  font-weight: 400;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 24px; }

.averages-box {
  margin: 20px 0 30px;
  padding: 20px;
  background-color: #fff; }

.giant-number {
  font-size: 120px;
  font-weight: 700;
  color: #FFF;
  transition: color $animate-speed;

  &.underweight {
    color: $underweight; }

  &.healthy-weight {
    color: $healthy-weight; }

  &.overweight {
    color: $overweight; }

  &.obese {
    color: $obese; }

  &.morbidly-obese {
    color: $morbidly-obese; } }

.giant-subtitle {
  font-size: 30px; }

.dashboard-insurance-table {

  .cell {
    text-align: center;
    height: 50px;
    border: none;
    color: $off-black;
    font-size: 18px;
    margin: 1px;

    .dashboard-insurance-arrow {
      display: none;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid black;
      margin-right: 5px;

      &.up, &.down {
        display: block; }

      &.down {
        transform: rotate(180deg); } }

    &.title {
      font-size: 20px;
      font-weight: 700; } }

  .green-cell {
    background-color: $healthy-weight; }

  .yellow-cell {
    background-color: $overweight; }

  .red-cell {
    background-color: $morbidly-obese; } }

.dashboard-mean-table {
  $dash-border: 1px solid $off-black;
  text-align: center;
  border-left: $dash-border;

  .mean-title {
    text-align: left;
    font-weight: 700; }

  .mean-boxed {
    font-weight: 700;
    border-top: $dash-border;
    border-bottom: $dash-border; }

  &>div>.frow>div {
    border-right: $dash-border;
    padding: 3px 10px; } }

.bmi-distribution-chart {
  & /deep/ .ct-series-a {
    path {
      fill: $underweight; } }
  & /deep/ .ct-series-b {
    path {
      fill: $healthy-weight; } }
  & /deep/ .ct-series-c {
    path {
      fill: $overweight; } }
  & /deep/ .ct-series-d {
    path {
      fill: $obese; } }
  & /deep/ .ct-series-e {
    path {
      fill: $morbidly-obese; } } }

.bmi-history-chart {
  & /deep/ .ct-series-a {
    path, .ct-point {
      stroke: $underweight; } }
  & /deep/ .ct-series-b {
    path, .ct-point {
      stroke: $healthy-weight; } }
  & /deep/ .ct-series-c {
    path, .ct-point {
      stroke: $overweight; } }
  & /deep/ .ct-series-d {
    path, .ct-point {
      stroke: $obese; } }
  & /deep/ .ct-series-e {
    path, .ct-point {
      stroke: $morbidly-obese; } } }

.mets-distribution-chart {
  & /deep/ .ct-series-a {
    path {
      fill: $healthy-weight; } }
  & /deep/ .ct-series-b {
    path {
      fill: $overweight; } }
  & /deep/ .ct-series-c {
    path {
      fill: mix($overweight, $obese); } }
  & /deep/ .ct-series-d {
    path {
      fill: $obese; } }
  & /deep/ .ct-series-e {
    path {
      fill: mix($obese, $morbidly-obese); } }
  & /deep/ .ct-series-f {
    path {
      fill: $morbidly-obese; } } }

.mets-history-chart {
  & /deep/ .ct-series-a {
    path, .ct-point {
      stroke: $healthy-weight; } }
  & /deep/ .ct-series-b {
    path, .ct-point {
      stroke: $overweight; } }
  & /deep/ .ct-series-c {
    path, .ct-point {
      stroke: mix($overweight, $obese); } }
  & /deep/ .ct-series-d {
    path, .ct-point {
      stroke: $obese; } }
  & /deep/ .ct-series-e {
    path, .ct-point {
      stroke: mix($obese, $morbidly-obese); } }
  & /deep/ .ct-series-f {
    path, .ct-point {
      stroke: $morbidly-obese; } } }
