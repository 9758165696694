@import "../../variables";

$gauge-data-url: "data:image/svg+xml,%3csvg viewBox='0 0 1 1' xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' x1='.209' y1='.791' x2='.5' y2='.088'%3e%3cstop offset='0%25' stop-color='%2348b16c'/%3e%3cstop offset='100%25' stop-color='%23fcc65c'/%3e%3c/linearGradient%3e%3clinearGradient id='b' x1='.5' y1='.088' x2='.791' y2='.791'%3e%3cstop offset='0%25' stop-color='%23fcc65c'/%3e%3cstop offset='100%25' stop-color='%23eb5a5d'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath d='M.209 .791A.412 .412 0 0 1 .52 .089' stroke='url(%23a)' stroke-width='.167' fill='none'/%3e%3cpath d='M.5 .088A.412 .412 0 0 1 .791 .791' stroke='url(%23b)' stroke-width='.167' fill='none'/%3e%3cpath d='M.15 .85A.495 .495 0 1 1 .85 .85L.732 .732A.328 .328 0 1 0 .268 .732Z' stroke='%23000' stroke-width='.01' fill='none'/%3e%3c/svg%3e";

.print-mets-bars {

  .print-mets-background {
    border: 1px solid $off-black;
    font-size: 10px;
    position: relative;
    height: 80px;
    width: 120px;
    margin: 0 10px;
    overflow: hidden;

    &.out-of-bounds {
      border: 2px solid $red; }

    &.green {
      background-color: $green; }

    &.yellow {
      background-color: $yellow; }

    &.red {
      background-color: $red; }

    .gauge {
      position: absolute;
      height: 90px;
      width: 100%;
      background-image: url($gauge-data-url);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      &.flip {
        transform: scale(-1, 1); } }


    .print-mets-range-display {
      position: absolute;
      top: 5px;

      &:first-child {
        left: 5px; }

      &:last-child {
        right: 5px; } }

    .print-mets-value {
      transform-origin: 50% 100%;
      transition: transform $animate-speed;

      .print-mets-number {
        background-color: #fff;
        border: 1px solid $off-black;
        border-radius: 50%;
        padding: 2px 4px;
        color: $off-black;
        font-weight: 700;
        font-size: 10px;
        transition: transform $animate-speed; }

      .print-mets-needle {
        height: 29px;
        width: 3px;
        border-radius: 10px;
        background-color: $off-black; } } }

  .smiley-face-container {
    position: absolute;
    bottom: 0;
    left: 50%;

    .smiley-face {
      width: 40px;
      height: 40px;
      margin-left: -20px;
      background-color: $white;
      border: 2px solid $off-black;
      border-radius: 50%;
      position: relative;

      .smiley-eye {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $off-black;
        position: absolute;
        top: 30%;

        &:nth-child(1) {
          left: 25%; }

        &:nth-child(2) {
          right: 25%; } }

      .smiley-nuetral {
        width: 20px;
        height: 2px;
        background-color: $off-black;
        position: absolute;
        bottom: 25%;
        left: 50%;
        margin-left: -10px; }

      .smiley-mouth-container {
        position: absolute;
        bottom: -55%;
        left: 50%;

        .smiley-mouth {
          width: 30px;
          height: 30px;
          margin-left: -15px;
          border: solid 2px $off-black;
          border-color: $off-black transparent transparent transparent;
          border-radius: 30px 30px 0 0;

          &.smile {
            transform-origin: 50% 0%;
            transform: rotate(180deg); } } } } }

  .print-mets-bar-title,
  .print-mets-bar-units {
    font-weight: 700;
    font-size: 10px;
    text-align: center; }

  .print-mets-bar-title {
    margin-top: 3px;
    z-index: 2;
    position: relative; } }
