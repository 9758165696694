//
// Flexbox shortcuts
// --------------------------------------------------

@mixin flexbox-rules {
  // Shortcuts
  &.centered {
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%; }

  &.centered-column {
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    height: 100%; }

  &.row-start {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }

  &.row-center {
    flex-direction: row;
    align-items: center;
    justify-content: center; }

  &.row-end {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; }

  &.row-between {
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }

  &.row-around {
    flex-direction: row;
    align-items: center;
    justify-content: space-around; }

  &.row-evenly {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; }

  &.column-start {
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start; }

  &.column-center {
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start; }

  &.column-end {
    flex-direction: column;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-start; }

  // Inline-Flex
  &.inline {
    display: inline-flex; }

  // Wrap
  &.wrap {
    flex-wrap: wrap; }

  &.wrap-reverse {
    flex-wrap: wrap-reverse; }

  &.nowrap {
    flex-wrap: nowrap; }

  // Flex-direction
  &.direction-row {
    flex-direction: row; }

  &.direction-reverse {
    flex-direction: row-reverse; }

  &.direction-column {
    flex-direction: column; }

  &.direction-column-reverse {
    flex-direction: column-reverse; }

  // Justify-content
  &.justify-start {
    justify-content: flex-start; }

  &.justify-end {
    justify-content: flex-end; }

  &.justify-center {
    justify-content: center; }

  &.justify-between {
    justify-content: space-between; }

  &.justify-around {
    justify-content: space-around; }

  &.justify-evenly {
    justify-content: space-evenly; }

  // Align-items
  &.items-start {
    align-items: flex-start; }

  &.items-end {
    align-items: flex-end; }

  &.items-center {
    align-items: center; }

  &.items-stretch {
    align-items: stretch; }

  &.items-baseline {
    align-items: baseline; }

  // Align-content
  &.content-start {
    align-content: flex-start; }

  &.content-end {
    align-content: flex-end; }

  &.content-center {
    align-content: center; }

  &.content-between {
    align-content: space-between; }

  &.content-around {
    align-content: space-around; }

  &.content-evenly {
    align-content: space-evenly; } }
