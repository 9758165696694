@import "../variables";

.reports-page {
  background-color: $background-color;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0; }

.cells {
  padding: 5px;

  &.titles {
    position: sticky;
    top: 0px;
    z-index: 3;
    background-color: $background-color; }

  select {
    margin: 0;
    text-align-last: center; } }

.wds-score-key {
  font-size: 10px; }
