@import "../variables";

.articles-page {
  background-color: $background-color; }

.tree-visible {
  .tree-container {
    display: block !important; } }

.tree-hidden {
  .article-container {
    display: block !important; } }

.articles-nav {
  margin-bottom: 10px;

  button, a {
    margin-left: 10px; } }

.articles-sidebar {
  padding: 0 20px;
  font-weight: 700; }

#article-content {
  background-color: #fff;
  min-height: 100%;
  padding: 35px 50px 50px;
  margin-bottom: 25px;

  .title {
    font-size: 40px;
    font-style: italic;
    margin-bottom: 20px; }

  & /deep/ h2,
  & /deep/ h3,
  & /deep/ h4,
  & /deep/ h5,
  & /deep/ h6, {
    color: $gray-darker; }

  & /deep/ img {
    max-width: 100%; }

  & /deep/ a,
  & /deep/ a:hover,
  & /deep/ a:active,
  & /deep/ a:focus {
    color: $blue; } }

/* XS */
@media (max-width: 767px) {
  #article-content {
    padding: 20px;
    margin-bottom: 25px; } }
