@import "../../variables";

nav {
  font-weight: 400;
  margin-left: 10px;
  font-size: 15px;

  a {
    display: block;
    margin-bottom: 5px;

    &.active {
      color: $blue; }

    &.folder {
      display: inline-block;
      font-weight: 700; } } }

.dropdown-triangle {
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid $gray-dark;
  transition: transform $animate-speed;

  &.open {
    transform: rotate(90deg); } }

/* XS */
@media (max-width: 767px) {
  nav {
    font-weight: 400;
    margin-left: 15px;
    font-size: 17px;

    a {
      margin-bottom: 10px;

      &.folder {
        margin-bottom: 10px; } } } }
